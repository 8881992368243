import React from 'react';
import { Link, graphql } from 'gatsby';
import Masonry from 'react-masonry-component';
import Img from 'gatsby-image';
import Layout from '../components/layout';

export default function IndexPage({ data }) {
    function getEdges(): any[] {
        return data.allDatoCmsWork.edges.filter(x => x.node.isenabled);
    }

    return (
        <Layout>
            <Masonry className="showcase">
                {getEdges().map(({ node: work }) => (
                    <div key={work.id} className="showcase__item">
                        <figure className="card">
                            <Link
                                to={`/works/${work.slug}`}
                                className="card__image"
                            >
                                <Img fluid={work.coverImage.fluid} />
                            </Link>
                            <figcaption className="card__caption">
                                <h6 className="card__title">
                                    <Link to={`/works/${work.slug}`}>
                                        {work.title}
                                    </Link>
                                </h6>
                                <div className="card__description">
                                    <p>{work.excerpt}</p>
                                </div>
                            </figcaption>
                        </figure>
                    </div>
                ))}
            </Masonry>
        </Layout>
    );
}

export const query = graphql`
    query IndexQuery {
        allDatoCmsWork(sort: { fields: [position], order: ASC }) {
            edges {
                node {
                    id
                    isenabled
                    title
                    slug
                    excerpt
                    coverImage {
                        fluid(
                            maxWidth: 1016
                            imgixParams: { fm: "webp", auto: "compress" }
                        ) {
                            ...GatsbyDatoCmsSizes
                        }
                    }
                }
            }
        }
    }
`;
